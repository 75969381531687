import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import HomeView from '../pages/custom/Home.vue'
import AboutView from '../views/AboutView.vue'
import TodosView from '../views/TodosView.vue'
//import JobsView from '../views/Jobs'
//import CoursesView from '../views/Courses'
import LoginView from '../pages/base/Login'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    sectionTitle:'Login',
    loggedOut: true,
    component: LoginView
  },

  { path: '/profile', name:'profile', title: 'Profiel', component: () => import('../pages/base/User/Profile') },
  { path: '/profile/password', name:'profile_password', title: 'Wachtwoord', component: () => import('../pages/base/User/Password') },

  // TOOLS
  { path: '/tools', icon:'fa-gear',sectionTitle:'Tools', name:'tools_mails', title: 'Mails', component: () => import('../pages/base/Tools/Mails') },
  { path: '/tools/log', name:'tools_lot', title: 'Log', component: () => import('../pages/base/Tools/Log') },
  { path: '/tools/permissions',   name:'tools_permissions', title:"Permissions", component: () => import('../pages/base/Tools/Permissions') },
  { path: '/tools/database', name:'tools_database', title: 'Database', component: () => import('../pages/base/Tools/Database') },
  { path: '/tools/migrate', name:'tools_migrate', title: 'Migrate', component: () => import('../pages/custom/Tools/Migrate') },
  { path: '/tools/cleartrajects', name:'tools_cleartrajects', title: 'Clear trajects', component: () => import('../pages/custom/Tools/ClearTrajects') },
  { path: '/tools/cache', name:'tools_cache', title: 'Cache', component: () => import('../pages/base/Tools/Cache') },
  

  //{ path: '/dashboard', sectionTitle:'Dashboard', icon:'fa-gauge', title:'Overzicht', name: 'dashboard_overview', component: () => import('../pages/custom/Dashboard/Overview')},
  
  // CUSTOMERS

  // DASHBOARD
  

  // CALENDAR
  //{ path: '/calendar', sectionTitle: 'Kalender', icon:'fa-calendar-days', name:'calendar',  title:"Overzicht", component: () => import('../pages/custom/Calendar/Overview') },


// PROJECTEN
// { path: '/certificates', sectionTitle:'Certificaten', icon:'fa-file-certificate',  title:'Mijn certificaten', name: 'certificates_overview', component: () => import('../pages/custom/Certificate/List') },
// { path: '/certificates/coaches',  name: 'certificates_coaches', title:'Coaches', component: () => import('../pages/custom/Certificate/Coaches'), meta:{title:'Coaches'} },
// { path: '/certificates/coaches/?id=:id',  name: 'certificates_coaches_id', component: () => import('../pages/custom/Certificate/Coaches'), meta:{title:'Coaches'} },
// { path: '/certificates/edit/:id', name: 'certificates_edit', component: () => import('../pages/custom/Certificate/Edit'), meta:{title:'Certificaat bewerken', controller:'certificates', afterSave:{all:'/certificates'}} },
// { path: '/certificates/templates', title:'Sjablonen', name: 'certificates_templates', component: () => import('../pages/custom/Certificate/Templates') },
// { path: '/certificates/template/:id', name: 'certificates_template_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Sjabloon bewerken', controller:'certificates', afterSave:{all:'/certificates'}} },

{ path: '/trajecten', sectionTitle:'Trajecten', icon:'fa-clipboard-user',  title:'Overzicht', name: 'trajects_overview', component: () => import('../pages/custom/Traject/List') },
{ path: '/trajecten/newreport/:id',  name: 'trajects_newreport', component: () => import('../pages/custom/Report/Edit') },
{ path: '/trajecten/editreport/:id', name: 'trajects_editreport', component: () => import('../pages/custom/Report/Edit') },
{ path: '/trajecten/editcertificate/:id', name: 'trajects_edit_certificate', component: () => import('../pages/custom/Certificate/Edit') },
{ path: '/trajecten/newcertificate/:id', name: 'trajects_add_certificate', component: () => import('../pages/custom/Certificate/Edit') },
{ path: '/trajecten/newcertificate/:id/:cho_id', name: 'trajects_add_choicelist_certificate', component: () => import('../pages/custom/Certificate/Edit') },

{ path: '/financieel', sectionTitle:'Financieel', icon:'fa-money-check-dollar',  title:'Overzicht', name: 'finance_overview', component: () => import('../pages/custom/Traject/Finance') },
{ path: '/financieel/coach/:id', name: 'finance_overview_coach', component: () => import('../pages/custom/Traject/Finance') },

{ path: '/choicelists', sectionTitle:'Keuzelijst', icon:'fa-list-radio',  title:'Versies', name: 'choicelists_versions', component: () => import('../pages/custom/Choicelist/Versions') },
{ path: '/choicelists/choicelists', title:'Lijsten', name: 'choicelists_overview', component: () => import('../pages/custom/Choicelist/List') },
{ path: '/choicelists/quadrants', title:'Kwadranten', name: 'choicelists_quadrants', component: () => import('../pages/custom/Choicelist/Quadrants') },
{ path: '/choicelists/itemgroups', title:'Hoofdgroepen', name: 'choicelists_itemgroups', component: () => import('../pages/custom/Choicelist/Itemgroups') },
{ path: '/choicelists/items', title:'Keuzes', name: 'choicelists_items', component: () => import('../pages/custom/Choicelist/Items') },

// KLANTEN
// { path: '/customers', sectionTitle:'Klanten', icon:'fa-people-group',  title:'Overzicht', name: 'customers_overview', component: () => import('../pages/custom/Customer/List') },
// { path: '/customers/branches', title:'Branches', name: 'customers_branches', component: () => import('../pages/custom/Customer/Branches') },
// { path: '/customers/stripcards', title:'Strippenkaarten', name: 'customers_stripcards', component: () => import('../pages/custom/Customer/Stripcards') },
// // { path: '/customers/edit/:id', name: 'customer_edit', component: () => import('../pages/custom/Customer/Edit') },
// // { path: '/customers/new', name: 'customer_add', component: () => import('../pages/custom/Customer/Edit') },
// { path: '/customers/edit/:id', name: 'customers_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant bewerken', controller:'customers', afterSave:{all:'/customers'}} },
// { path: '/customers/details/:id', name: 'customers_details', component: () => import('../pages/custom/Customer/Details'), meta:{title:'Klant details', controller:'customers'} },
// { path: '/customers/new', name: 'customers_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant toevoegen', controller:'customers', afterSave:{all:'/customers'}} },
// { path: '/customers/:id/newcontactperson', name: 'customers_addcontactperson', component: () => import('../pages/base/_Entity/AddRelation'), meta:{title:'Contactpersoon toevoegen', controller:'customers', relationController:'contactpersons', entity:'Contactperson', sourceIdField:'cus_id', afterSave:{all:'/customers/details/:id'}} },


// // FACTUREN
// { path: '/invoices', sectionTitle:'Facturen', icon:'fa-euro', title:'Overzicht', name: 'invoices_overview', component: () => import('../pages/custom/Invoice/List'), meta:{type:'open'} },
// { path: '/invoices/openrules', title:'Te factureren', name: 'invoices_openrules', component: () => import('../pages/custom/Invoice/OpenRules') },
// { path: '/invoices/overdue', title:'Vervallen', name: 'invoices_overdue', component: () => import('../pages/custom/Invoice/List'), meta:{type:'overdue'} },
// { path: '/invoices/unpaid', title:'Open', name: 'invoices_unpaid', component: () => import('../pages/custom/Invoice/List'), meta:{type:'unpaid'} },
// { path: '/invoices/new/:cus_id', name: 'invoices_new', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur toevoegen', controller:'invoices'} },
// { path: '/invoices/new/:cus_id/:pro_id', name: 'invoices_new_project', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur toevoegen', controller:'invoices'} },
// { path: '/invoices/edit/:id', name: 'invoices_edit', component: () => import('../pages/custom/Invoice/Edit'), meta:{title:'Factuur bewerken', controller:'invoices'} },


// // MEDEWERKERS
{ path: '/users', sectionTitle:'Gebruikers', icon:'fa-person-chalkboard', title:'Overzicht',name: 'users_overview', component: () => import('../pages/custom/User/List') },
// { path: '/users/edit/:id', name: 'user_edit', component: () => import('../pages/base/User/Edit') },
// { path: '/users/new', name: 'user_add', component: () => import('../pages/base/User/Edit') },
{ path: '/users/details/:id', name: 'users_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Gebruiker details', controller:'users'} },
{ path: '/users/edit/:id', name: 'users_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker bewerken', controller:'users', afterSave:{all:'/users'}} },
{ path: '/users/new',  name: 'users_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker toevoegen', controller:'users', afterSave:{all:'/users'}} },

// // roles
{ path: '/users/roles', sectionTitle:'Rollen', title:'Rollen', name: 'roles_overview', component: () => import('../pages/base/Role/List') },
{ path: '/roles/edit/:id', name: 'roles_edit', component: () => import('../pages/base/Role/Edit') },
{ path: '/roles/new', title:'Toevoegen', name: 'roles_add', component: () => import('../pages/base/Role/Edit') },


// // LIBRARY
{ path: '/library', sectionTitle: 'Bibliotheek', icon:'fa-photo-film', className:'hasFileTree', name:'library', title:"Overzicht", component: () => import('../pages/custom/Library/Overview') },

// SETTINGS
{ path: '/settings', sectionTitle: 'Instellingen', icon:'fa-gears', name:'settings', title:"Variabelen", component: () => import('../pages/base/Settings/Overview') },
{ path: '/settings/mailstatus', name:'settings_mailstatus', title:"Mail status", component: () => import('../pages/base/Settings/Mailstatus') },
{ path: '/settings/mailtemplates', name:'settings_mailtemplates', title:"Mail templates", component: () => import('../pages/base/Settings/Mailtemplates') },
{ path: '/settings/msgraph', name:'settings_msgraph', title:"Microsoft", component: () => import('../pages/base/Settings/MSGraph') },
{ path: '/settings/templates', title:'Sjablonen', name: 'certificates_templates', component: () => import('../pages/custom/Certificate/Templates') },


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_COMPANY_NAME;
  next();
});

export default router
