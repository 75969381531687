<template>

    <div class="loginScreen">
        <figure class="loginLogo">
            <img :src="logoDefault" :alt="companyName+' logo'" />
        </figure>
        <div class="loginPanel" v-if="consentDocument">
    
            
            <h1>Overeenkomst accepteren</h1>
            <p>Om verder te gaan moet u akkoord gaan met de <strong>{{consentDocument}}</strong>.
            <br />
            <br />
            <a :href="documentLink" target="_blank">Lees de overeenkomst &raquo;</a>
            </p>
            <FormKit type="form" v-model="_consent" :actions="false" @submit="saveConsent">
                <FormKitSchema :schema="_formFields" :data="_schemaData" />
            </FormKit>
            
        </div>
    </div>
   
</template>

<script>
export default {
    data() {
        return {
            _consent:{},
            _formFields:[
                
                
                {name:'consent', id:'consent', label:'Ik heb de overeenkomst gelezen en ga hiermee akkoord', '$formkit':'checkbox', realClass:'checkbox', 'wrapper-class':'formkit-wrapper-checkbox'},
                {name: "Save","$el": "button","children": "Verder","attrs": {"onClick": "$submitForm","class": "btn"}}
                
            ]
        }
    },
    computed: {
        logoDefault() { return process.env.VUE_APP_LOGO_DEFAULT; },
        logoFull() { return process.env.VUE_APP_LOGO_FULL; },
        logoCollapsed() { return process.env.VUE_APP_LOGO_COLLAPSED; },
        companyName() { return process.env.VUE_APP_COMPANY_NAME; },

        consentDocument() {
            if (!this.getLogin.consentDocument) {
                return false;
            }
            return this.getLogin.consentDocument.description
        },
        documentLink() {
            if (!this.getLogin.consentDocument) {
                return false;
            }
            return process.env.VUE_APP_API_BASE+"/consent/displayPdf/"+this.getLogin.consentDocument.id;
        },  
    },
    methods: {
        async saveConsent() {
            console.log(this._consent)
            if (!this._consent.consent) {
                this.showErrorMessage('Fout', 'Om verder te gaan moet u de overeenkomst accepteren.')
                return;
            }
            const res = await this.callApi('post', '/consent/saveUserConsent', {id:this.getLogin.consentDocument.id});
            window.location.reload();
        }
    },
    
    mounted() {
        //document.getElementById('consentDialog').showModal();
    }
}
</script>

<style scoped>
.loginPanel {
    width: 72rem;
    text-align: left !important;
}
.loginPanel p {
    font-size: 1.4rem;
    margin-bottom:2rem;
}

</style>