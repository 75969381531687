import { shallowRef} from 'vue'

import ConsentDialog from '../components/custom/Consent/Dialog.vue';

export default {
    computed: {
        hasAdminRights() {
            if (!this.$store.getters.get_login) return false;
            switch (this.$store.getters.get_login.Role.name) {
                case 'Superuser':
                case 'Admin':
                case 'Beheerder':
                    return true;
                    break;
            }
            return false;
        },
        showFullEditLink() {
            
            switch(this._data._class) {
                case 'Mail':
                case 'Client':
                case 'MailTemplateBlock':
                case 'BaseCourseAction':
                case 'Mailtemplate':
                    return false;
                    break;
                default:
                    return true;
                    break;
            }
        },
    },
    methods: {
        

        afterCallApi(res) {
            //console.log('afterCallApi', 'custom', res)
            if (res.data && res.data.login && !res.data.login.hasConsent) {
                console.log('show custom consent')
                this.emitter.emit('showCustomComponent', {component:shallowRef(ConsentDialog)});
            }
        },

        getEditSubTitle() {
            
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'Client':                entityName = ''; break;
                case 'Contactperson':         entityName = 'Contactpersoon'; break;
                case 'Project':               entityName = 'Project'; break;
                case 'Customer':              entityName = 'Klantgegevens'; break;
                case 'Invoice':               entityName = 'Factuur'; break;
                case 'Organisation':          entityName = 'Organisatie'; break;
                
            }
            return entityName;
            
        },
        getEditTitle() {
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'Contactperson':       entityName = 'Contactpersoon'; break;
                case 'Project':             entityName = 'Project'; break;
                case 'Customer':            entityName = 'Klant'; break;
                case 'Organisation':        entityName = 'Organisatie'; break;
                
            }
            return entityName;
            
        },
        getEditName(data) {
            if (!data.id) return '';
            var html = [];

            
            switch(data._class) {
                case 'BaseCourse':
                case 'Course':
                case 'CourseLocation':
                case 'Customer':    
                    html.push(data.name+' - ID: '+data.id);
                    break;
                case 'Participant':    
                    html.push(data.fullname+'  ('+data.Customer.name+')');
                    break;
                case 'User':    
                    html.push(data.fullname+' - ID: '+data.id);
                    break;
                case 'MailTemplate':    
                    html.push((data.BaseCourse.acronym?data.BaseCourse.acronym:'Default')+(data.type?' - '+data.type+' template':''));
                    break;
                case 'Enrollment':    
                    html.push(data.Participant.fullname+'  ('+data.Customer.name+')');
                    break;
                case 'BaseCourseAction':    
                    html.push(data.BaseCourse.name);
                    break;
            }
            return html.join('<br />');
        },
    }
}
