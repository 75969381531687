import { createStore } from 'vuex'

export default createStore({
  state: {
    loaderstate: false,
    login: {},
    lastlogin: false,
    settings: [],
    activities: null,
    dashboardusers: null,
    alertcounts: null,
    mailtemplates: null,
    templates: null,
    choicelists: null,
    versions: null,
    regions: null,
    agecategories: null,
    roles: []
  },
  getters: {
    get_loaderstate: state => { return state.loaderstate; },
    get_login: state => { return state.login; },
    get_lastlogin: state => { return state.lastlogin; },
    get_settings: state => { return state.settings; },
    get_activities: state => { return state.activities; },
    get_dashboardusers: state => { return state.dashboardusers; },
    get_alertcounts: state => { return state.alertcounts; },
    get_mailtemplates: state => { return state.mailtemplates; },
    get_templates: state => { return state.templates; },
    get_choicelists: state => { return state.choicelists; },
    get_versions: state => { return state.versions; },
    get_regions: state => { return state.regions; },
    get_agecategories: state => { return state.agecategories; },
    get_roles: state => { return state.roles; }
  },
  mutations: {
    SET_LOADERSTATE(state, val) { state.loaderstate = val; },
    SET_LOGIN(state, val) { state.login = val; },
    SET_LASTLOGIN(state, val) { state.lastlogin = val; },
    SET_SETTINGS(state, val) { state.settings = val; },
    SET_ACTIVITIES(state, val) { state.activities = val; },
    SET_DASHBOARDUSERS(state, val) { state.dashboardusers = val; },
    SET_ALERTCOUNTS(state, val) { state.alertcounts = val; },
    SET_MAILTEMPLATES(state, val) { state.mailtemplates = val; },
    SET_TEMPLATES(state, val) { state.templates = val; },
    SET_CHOICELISTS(state, val) { state.choicelists = val; },
    SET_VERSIONS(state, val) { state.versions = val; },
    SET_REGIONS(state, val) { state.regions = val; },
    SET_AGECATEGORIES(state, val) { state.agecategories = val; },
    SET_ROLES(state, val) { state.roles = val; }
  },
  actions: {
    set_loaderstate({ commit }, val) { commit('SET_LOADERSTATE', val); },
    set_login({ commit }, val) { commit('SET_LOGIN', val); },
    set_lastlogin({ commit }, val) { commit('SET_LASTLOGIN', val); },
    set_settings({ commit }, val) { commit('SET_SETTINGS', val); },
    set_activities({ commit }, val) { commit('SET_ACTIVITIES', val); },
    set_dashboardusers({ commit }, val) { commit('SET_DASHBOARDUSERS', val); },
    set_alertcounts({ commit }, val) { commit('SET_ALERTCOUNTS', val); },
    set_mailtemplates({ commit }, val) { commit('SET_MAILTEMPLATES', val); },
    set_templates({ commit }, val) { commit('SET_TEMPLATES', val); },
    set_choicelists({ commit }, val) { commit('SET_CHOICELISTS', val); },
    set_versions({ commit }, val) { commit('SET_VERSIONS', val); },
    set_regions({ commit }, val) { commit('SET_REGIONS', val); },
    set_agecategories({ commit }, val) { commit('SET_AGECATEGORIES', val); },
    set_roles({ commit }, val) { commit('SET_ROLES', val); }
  }
})